import {
    // parseSearchAppConfigs,
    createSearchAppsInit,
} from "@js/oarepo_ui";

export * from "./components";

export const componentOverrides = {};

createSearchAppsInit({componentOverrides});
